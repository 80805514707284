var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-card", on: { click: _vm.data.click } },
    [
      _c("div", [
        _c("div", {
          staticClass: "mb12 fw500",
          domProps: { textContent: _vm._s(_vm.$t(_vm.types[_vm.user.type])) },
        }),
        _c(
          "div",
          { staticClass: "flex a-center" },
          [
            _c("user-avatar", { attrs: { size: 40, user: _vm.user } }),
            _c("div", { staticClass: "mis16" }, [
              _c("div", {
                staticClass: "mb8",
                domProps: {
                  textContent: _vm._s(
                    _vm.user.first_name + " " + _vm.user.last_name
                  ),
                },
              }),
              _c("div", { domProps: { textContent: _vm._s(_vm.user.phone) } }),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "user-card__arr" },
        [
          _c("svg-icon", {
            attrs: { name: "ic-arrow-down", width: "18", height: "10" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }